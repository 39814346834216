html,
body {
  overflow-x: hidden;
}

.home {
  background-color: #000f1e;
  width: 100vw;
}

.header {
  background: url(../assets/eventshorizontal.jpg);
  /* background: url(../assets/bg2.png); */
  /* background-attachment:; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  row-gap: 1rem;
  padding-left: 30px;
  padding-right: 30px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 40px
}

.button1 {
  /* width: 120px; */
  color: white;
  background-color: #610909;
  border: 0px;
  /* height: 50px; */
  padding: 10px 20px;
  border-radius: 18px;
  cursor: pointer;
  font-size: 1.2em;
  margin: 10px;
}

.button1:hover {
  background-color: white;
  color: #610909;
  /* transform: scale(1.05); */
  transition: transform 0.2s, background-color 0.3s, color 0.3s;
  /* transition-delay: 0.2s; */
}

.logo img {
  width: 10vw;
  max-width: 75px;
  /* height: 15vh; */
}

.centre {
  position: inherit;
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: translateY(2vh);
  /* background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%); */
  padding-right: -10px;
  border-radius: 25px;
}

.centresmall{
  display: none;
}

.centre img {
  width: 65vw;
  min-width: 500px;
}

.date {
  margin: 10px;
  color: white;
  font-size: 1.5vw;
  position: absolute;
  top: 72%;
  left: 60%;
  text-align: center;
}

.text {
  backdrop-filter: blur(4px);
  height: 10vh;
  width: 24vw;
  align-items: center;
  min-width: 20vw;
  transform: translateY(15vh);
  /* overflow: hidden; */

}

.inner {
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.3vw;
  height: 10vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

@import url('https://fonts.googleapis.com/css2?family=Karantina:wght@300;400;700&display=swap');

.events {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: max(2.5vw, 2rem);
  font-family: "Karantina";
  font-weight: 400;
  letter-spacing: 2px;
}

.hr1 {
  height: 2.9px;
  width: 85%;
  background-color: #FFC370;
  border: #FFC370;
  border-radius: 15px;
  border: 0cap;
  position: absolute;
  top: 0vh;

}

.hr2 {
  height: 2.9px;
  width: 85%;
  background-color: #FFC370;
  border: #FFC370;
  border-radius: 15px;
  border: 0cap;
  position: absolute;
  top: 0.8svh;
}

.hr3 {
  height: 2.7px;
  width: 85%;
  background-color: #FFC370;
  border: #FFC370;
  border-radius: 15px;
  border: 0cap;
  position: absolute;
  bottom: 0vh;
}

.hr4 {
  height: 2.5px;
  width: 85%;
  background-color: #FFC370;
  border: #FFC370;
  border-radius: 15px;
  border: 0cap;
  position: absolute;
  bottom: 0.8svh;
}

.btn {
  cursor: pointer;
  position: absolute;
  border-radius: 10px;
  width: auto;
  height: auto;
  z-index: 1000;
  bottom: 8%;
}

.contactwrapper {
  margin-top: auto;
  background-image: url('../assets/backcompjpg.jpg');
  /* background: linear-gradient(#613612, rgb(255, 255, 255)); */
  background-size: repeat-y;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  /* box-shadow: 0 12px 20px #fcce6b inset; */
}

.heading {
  color: white;
  text-align: center;
  margin-top: 50px;
}

.organizersSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 60px 0 40px 0; 
  /* min-height: 68vh; */
}

.logoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 10px 0;
}
.contact {
  padding: 5px 45px;
  color: white;
  background-color: rgba(97, 9, 9, 1);
  border-radius: 195px;
  display: flex;
  font-size: 1.6em;
  text-align: center;
  justify-content: center;
}

.eventsContainer {
  /* background: url(../assets/eventsBG.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  height: auto;
  /* min-height: 100vh; */
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.wave1 {
  position: absolute;
  bottom: 0;
  left: -10%;
  width: 200vw;
  opacity: 0.7;
  display: flex;
  height: 50px;
  align-items: flex-end;
  justify-content: flex-end;
}

.wave2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200vw;
  opacity: 0.6;
  display: flex;
  height: 50px;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 450px) {
  .header{
    background: url(../assets/eventsverticalbg.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
  }

  .centre{
    transform: translateY(-8vh);
  }
  .centrebig{
    display: none;
  }
  .centresmall{
    display: block;
    transform: translateX(8px);
  }
  .centre img {
    margin-top: 15px;
    min-width: 80vw;
  }

  .events {
    font-size: 6vw;
    /* margin-left: -1vw; */
    text-align: center;
    display: hidden;
    /* width: 100px; */
  }

  .text {
    width: 35vw;
    position: relative;
    transform: translateY(30vh);
  }

  .date {
    font-size: 0.7em;
    left: 53%;
  }
  .organizersSection{
    margin-bottom: 15px;
  }
  .wave1, .wave2{
    /* transform: translateY(5vh); */
    height: 70px;
    /* background: black; */
  }
  .inner{
    transform: translateY(-2.5vh);
  }
}

@media screen and (min-width: 450px) and (max-width: 600px) {
  .header {
    height: 100vh;
    background-position: 50% 0;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    right: 0px
  }

  .centre img {
    min-width: 80vw;
  }

  .events {
    position: inherit;
    top: -4.5vh;
    left: 4vw;
    font-size: 5vw;
    text-align: center;
    display: hidden;
    width: 20vw;
  }

  .text {
    width: 30vw;

  }

  .hr3 {
    position: absolute;
    top: 8vh;
  }

  .hr4 {
    position: absolute;
    top: 8.7vh;
  }

  .date {
    font-size: 0.7em;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .maintitle {
    font-size: 1.8em;
  }

  .mainsubtitle {
    font-size: 1.5em;
  }

  .date {
    font-size: 1.1em;
  }

  .text {
    min-width: 20vw;
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .events {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .hr3 {
    position: absolute;
    top: 7.8vh;
  }

  .hr4 {
    position: absolute;
    top: 8.5vh;
  }



}

