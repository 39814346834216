.embla {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  --slide-height: max(500px, 50vh);
  --slide-spacing: 0;
  --slide-size: min(70vw, 350px);
}
.embla__viewport {
  overflow: hidden;
  width: max(90%, 150px);
  position: relative;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234);
  cursor: pointer;
  border-radius: 50px;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: var(--slide-height);
  background-size: cover;
  background-position: center;
  position: relative;
  /* transition: all 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95); */
}
.embla__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.embla__button:disabled {
  color: rgb(192, 192, 192);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  --dots-slide-spaceing: 1rem;
  --dots-slide-height: 1rem;
  margin-top: var(--dots-slide-spaceing);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__dot {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  flex: 0 0 15%;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  position: relative;
}
.embla__dot img{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.embla__dot:after {
  background-color: #61090930;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
/* .embla__dot--selected:after {
  transition: height 0.2s linear, width 0.2s linear;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
} */
.embla__dot--selected img{
  transition: height 0.2s linear, width 0.2s linear;
  width: 60px;
  height: 60px;
}

.embla-dots__viewport {
  overflow: hidden;
}
.embla-dots__container {
  display: flex;
  flex-direction: row;
  align-items: center ;
  height: 80px;
  margin-left: calc(var(--dots-slide-spaceing) * -0.75);
}

.events-name {
  text-wrap: pretty;
  min-height: 45px;
  min-width: 50%;
  max-width: 70%;
  padding: 0.5rem 1rem;
  height: auto;
  border-radius: 50px;
  background: rgb(255,249,190);
  background: linear-gradient(180deg, rgba(255,249,190,1) 0%, rgba(250,178,109,1) 100%);
  position: absolute;
  bottom: 5%;
  font-size: 1.25rem;
  text-transform: uppercase;
  text-align: center;
  display: grid;
  place-items: center;
  cursor: pointer;
}