.container{
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
}

.hidden{
    display: none;
}

.imgContainer{
    position: absolute;
    z-index: inherit;
    top: 0;
    right: 50%;
    transform: translateX(50%);
}

.imgContainer > img{
    width: max(30vw, 600px);
}

.imgText{
    position: absolute;
    color: white;
    height: 100%;
    bottom: 30%;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    row-gap: 1rem;
}

.linkContainer{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 70%;
    row-gap: 1rem;
    font-size: 1.2rem;
}

.btn, .JoinBtn{
    border-radius: 10px;
    width: 40%;
    height: 2rem;
    display: grid;
    place-items: center;
    font-weight: bolder;
    font-size: 1.25rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    background-color: #610909;
    /* background-image: linear-gradient(
        -45deg, 
        red,
        red 15%,
        orange 15%,
        orange 30%,
        yellow 30%,
        yellow 45%,
        green 45%,
        green 60%,
        blue 60%,
        blue 75%,
        indigo 75%,
        indigo 90%,
        red 90%,
        red
    ); */
    text-shadow: 1px 1px 2px black;
    transition: transform 0.2s linear;
}

.btn:hover, .JoinBtn:hover{
    background-color: white;
    color: #610909;
    transform: translateY(-0.5px);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 7px 29px 0px;
}

.close{
    position: absolute;
    bottom: 60%;
    right: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    color: black;
    display: grid;
    place-items: center;
    font-size: 25px;
    font-weight: bolder;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 7px 29px 0px;
    cursor: pointer;
    z-index: inherit;
}

@keyframes swing {
    0% { transform: rotate(3deg);}
    100% { transform: rotate(-3deg);}
}

@media screen and (max-width: 450px) {
    .imgContainer > img{
        width: max(90vw, 250px);
    }
    .imgText > h1{
        font-size: 1rem;
        /* position: absolute;
        top: -65%;
        right: 50%;
        transform: translateX(55%); */
    }
    .imgText{
        row-gap: 10px;
        bottom: 35%;
    }
    .linkContainer{
        font-size: 0.75rem;
    }
    .text{
        width: 60%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn, .JoinBtn{
        width: 80px;
        height: 1rem;
        font-size: 0.8rem;
    }
    .JoinBtn{
        text-indent: -9999px;
        line-height: 0;
    }
    .JoinBtn::after{
        content: "Link";
        text-indent: initial;
        display:block;
        line-height: initial;
    }
    .close{
        height: 20px;
        width: 20px;
        font-size: 15px;
    }
}

@media screen and (min-width: 450px) and (max-width: 768px){
    .imgContainer > img{
        width: max(90vw, 250px);
    }
    .imgText > h1{
        font-size: 1.5rem;
    }
    .imgText{
        row-gap: 0.8rem;
        bottom: 35%;
    }
    .text{
        width: 60%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn, .JoinBtn{
        width: 150px;
        height: 1.5rem;
        font-size: 0.8rem;
    }
    .close{
        height: 30px;
        width: 30px;
        font-size: 15px;
    }
}