.main-card {
  width: 230px;
  height: 310px;
  border-radius: 15px;
  background-color: white;
  letter-spacing: 1px;
  margin: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.main-card:hover {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
  transform: scale(1.03);
}

.upper-image {
  width: 100%;
  height: 180px;
  /* background-image: url(../assets/Events/music.jpg); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px 15px 0 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
}

.upper-image img {
  border-radius: 15px 15px 0 0;
}

.event-title {
  padding: 60px 0 10px 15px;
  /* width: 124px; */
  background: linear-gradient(180deg, rgb(0, 0, 0, 0), black);
  overflow: hidden;
}

.lower-box {
  height: 110px;
  padding: 10px 15px;
  border-radius: 0 0 15px 15px;
  font-size: 14px;
  overflow: hidden;
}

.lower-box p {
  text-align: end;
  color: maroon;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 5px;
}

.lower-box p:hover {
  text-decoration: underline;
}

.action {
  text-decoration: none;
  color: var(--color2);
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
}

.event-desc {
  text-align: left;
  line-height: 1.1;
  height: 90px;
  overflow: hidden;
}

@media (width <= 500px) {
  .event-title {
    padding: 20px 1rem 10px 15px;
    font-size: 12px;
  }
}
