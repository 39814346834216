.organizercard {
  position: relative;
  max-width: 17.5rem; /* 280px */
  height: 25rem; /* 400px */
  padding: 0px 15px;
  border-radius: 0.9375rem; /* 15px */
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  margin: 5px;
}

.organizercard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/Events/card_backgroundfixed.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(122, 64, 25, 0.3);
  z-index: 1;
  border-radius: inherit;
}

.organizercard:hover::before {
  background-image: url('../assets/ContactCardBG.png');
}

.organizercard>* {
  position: relative;
  z-index: 2;
}

.organizerimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  width: 50%;
  width: 7rem; /* 112px */
  height: 7rem; /* 112px */
  border-radius: 50%; 
  object-fit: cover;
}

.organizerdetails {
  flex: 1;
  text-align: center;
}

.organizercard:hover .organizerdetails h4 {
  color: #FAB26D;
}

.h3 {
  text-align: center;
  margin: 0 auto;
  font-size: 1.2rem;
  color: rgb(92, 29, 29);
  font-weight: 1000;
  white-space: nowrap;
  width: fit-content;
}

.organizercard:hover .h3 h3 {
  color: #ffffff;
}

.organizerdetails h4 {
  text-align: center;
  margin-bottom: 5px;
  margin-right: 5px;
  margin: 1px;
  padding: 7px;
  color: rgb(64, 63, 63);
}

.organizersociallinks {
  margin-top: 10px;
}

.sociallink {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 1.7rem; /* 27.2px */
  color: rgb(92, 29, 29);
  padding: auto;
  /* margin-bottom: 5px; */
  transition: transform 0.3s ease;
}

.organizercard:hover .sociallink {
  color: #ffffff;
}

.organizercard:hover .sociallink:hover {
  color: #FAB26D;
}