.container{
    margin: 2.5rem;
    display: flex; 
    position: relative; 
    justify-content: space-evenly; 
    align-items: center; 
    border-radius: 9999px; 
    width: 450px;
    height: 80px;
}

.card-left{
    position: absolute; 
    height: 20px;
    width: 40px;
    background: #8bb2a9;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -100;
}

.card-right{
    position: absolute; 
    height: 20px;
    width: 40px;
    background: #8bb2a9;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -100;
}

.buttonContainer {
    display: flex; 
    justify-content: space-around; 
    align-items: center; 
    border-radius: 9999px; 
    background: #8BB2A9;
    height: 50px;
    width: min(90vw, 300px);
}

.button{
    display: flex; 
    color: #ffffff; 
    font-size: 1.5rem;
    line-height: 2rem; 
    font-weight: 700; 
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    height: 40px; 
    border-radius: 9999px; 
    background: #610909;
    border: 5px solid #8bb2a9;
    cursor: pointer;
}

.menu{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    border-radius: 50%;
    border: 5px solid #8bb2a9;
    height: 40px;
    width: 40px;
    background: #610909;
    cursor: pointer;
}

@media screen and (max-width: 650px) {
    .menu{
        display: none;
    }
}