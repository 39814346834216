.dashboard {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  position: relative;
  height: 65px;
  width: 100%;
  left: 15px;
  top: 7px;
}

.navbar {
  background-color: #907248;
  overflow: hidden;
  width: 100%;
  height: 70px;
  position: fixed;
  transition: transform 0.3s ease-in-out;
}

.navbarOpen {
  transform: translateX(0);
}

.navList {

  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.navItem {
  cursor: pointer;
  color: black;
  align-content: center;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
}

.active::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 2px solid #FFEDDC;
  margin-top: 6px;
}

.content {
  background-image: url("../assets/backcompjpg.jpg");

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  color: white;

}

.logout {
  position: relative;
  height: 40px;
  width: 8%;
  background-color: #610909;
  border-radius: 30px;
  color: white;
  right: 10px;
  font-size: 15px;
}

.logout:hover {
  background-color: white;
  color: #610909;
  /* transform: scale(1.05); */
  transition: transform 0.2s, background-color 0.3s, color 0.3s;
  /* transition-delay: 0.2s; */
}
.nav__toggle,
.nav__close {
  display: none;
}


@media (max-width: 600px) {
  .navbar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform: translateX(-100%);
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(40px);
  }

  .navbarOpen {
    transform: translateX(0);
    background-color: rgba(255, 255, 255, 0.034);
    backdrop-filter: blur(40px);
  }

  .navList {
    flex-direction: column;
    /* height: 50%; */
    width: 70%;
    left: 15%;
    top: 25%;
    bottom: 25%;
    border-radius: 15px;
    position: relative;
    background-color: #8e642a;
    padding: 20px 0 10px 0;
  }

  .logout {
    height: 25px;
    width: 20%;
    left: 2px;
    bottom: 10px;
    padding: 5px 25px;
    margin: 25px 0 20px 0;
  }

  .navToggle,
  .navClose {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: white;
  }

  .navToggle {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .navClose {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}