.dance {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: url("../assets/backcompjpg.jpg");
  background-size: repeat-y;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.content {
  flex: 1;
  overflow: auto;
  padding-bottom: 20px;
}

.pagetitleContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.pagetitle {
  color: maroon;
  font-size: 55px;
  font-weight: 1000;
  font-family: 'karantina', sans-serif;
  text-align: center;
}

.aboutdance {
  margin: 0 auto;
  padding: 20px;
  color: black !important;
  width: 70%;
  font-size: 20px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  position: relative;
  font-style: 'Inder', sans-serif;
}

.aboutdance,
.aboutdance * {
  color: black !important;
}

.aboutdance::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: maroon;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rulestitle {
  width: 80%;
  margin: 20px auto;
  font-size: 25px;
  font-family: 'Karantina', sans-serif;
  color: black;
}

.ruleslist {
  margin: 20px auto;
  padding: 10px 20px;
  color: black;
  width: 80%;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.comp {
  margin: auto;
  padding: 10px 20px;
  color: rgb(201, 101, 0);
  width: 80%;
  font-size: 20px;
}

.complist {
  font-weight: 600;
  margin: auto;
  color: rgb(201, 101, 0);
  width: 80%;
  font-size: 20px;
}

.comptitle {
  color: maroon;
  font-size: 40px;
  font-weight: 1000;
  font-family: 'karantina', sans-serif;
  text-align: center;
}

.wings {
  position: relative;
  height: auto;
  width: 250px;
}

.compecards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
  border-radius: 20px;
}

.aftertitle {
  color: maroon;
  font-size: 40px;
  font-weight: 1000;
  font-family: 'karantina', sans-serif;
  text-align: center;
}

.aftercontent {
  width: 80%;
  margin: auto;
  color: black;
  font-size: 20px;
}

.comprules {
  color: rgb(180, 101, 4);
  width: 70%;
  margin: auto;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: normal;
}

.rulebutton {
  font-size: 1em;
  font-family: 'Inknut Antiqua', sans-serif;
  font-weight: 600;
  color: #FFDC88;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: maroon;
  margin-bottom: 2px;
  padding-left: 10px;
}

.guidelines {
  color: maroon;
  font-size: 50px;
  font-weight: 1000;
  font-family: 'karantina', sans-serif;
  text-align: center;
}

.afterguidelines {
  background-image: url("../assets/bggold2.png");
  margin: -10px auto;
  padding: 20px;
  width: 70%;
  font-size: 18px;
  font-family: 'Inder', sans-serif;
  color: black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.afterguidelines ul {
  list-style-type: disc;
  padding-left: 20px;
}

.afterguidelines li {
  margin-bottom: 10px;
}

.buttonContainer {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

/* .button {
  background-color: maroon;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
} */

.button {
  width: 100px;
  color: white;
  background-color: #610909;
  border: 0px;
  height: 45px;
  border-radius: 18px;
  cursor: pointer;
  font-size: 1.2em;
  /* margin: 10px; */
  margin-bottom: 20px;

}

.button:hover {
  background-color: white;
  color: #610909;
  transform: scale(1.1);
  transition: transform 0.2s, background-color 0.3s, color 0.3s;
}

.accordionContent,
.accordionContent * {
  color: black !important;
}

.aboutContacts {
  margin: 20px auto;
  padding: 10px;
  color: black !important;
  width: 70%;
  font-size: 20px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  position: relative;
  font-style: 'Inder', sans-serif;
}

.aboutContacts,
.aboutContacts * {
  color: black !important;
}

.aboutContacts::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: maroon;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media screen and (max-width: 600px) {
  .pagetitleContainer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .pagetitle {
    display: flex;
    margin-top: 45px;
    margin-bottom: 0;
    font-size: 1.8em;
    flex-wrap: nowrap;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    right: 0px;
    top: 15px;
  }

  .aboutdance {
    margin-top: 10px;
    padding-top: 0px;
    font-size: 0.8em;

  }

  .wings {
    margin-top: 2em;
    height: 2.5em;
    width: 6.6em;
  }

  .afterguidelines {
    font-size: 0.8em;
  }

  .guidelines {
    font-size: 1.5em;
  }

  .button {
    font-size: 0.8em;
    width: 6em;
    padding: 0.3em;
    height: auto;
  }

  .comptitle {
    margin-top: 1em;
    font-size: 1.5em;
    margin-bottom: 0.2em;
  }

  .accordionContent {
    font-size: 0.8em;
  }

  .rulebutton {
    font-size: 0.6em;
    width: 100%;
    height: auto;
  }

  .comp {
    font-size: 0.8em;
  }

  .aftertitle {
    font-size: 1.5em;
    margin-top: -1em;
    margin-bottom: 0.5em;
  }

  .aboutContacts,
  .aboutContacts * {
    font-size: 0.95em !important;
    margin-top: auto;
  }

}