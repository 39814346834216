@import url('https://fonts.googleapis.com/css2?family=Karantina:wght@700&display=swap');

:root {
  --headingColor: #000;
  --labelColor: #555;
  --valueColor: #000;
  --decorativeImageUrlLeft: url('../assets//Events/wings-removebg-preview\ 1.png');
  --decorativeImageUrlRight: url('../assets/Events/wings-removebg-preview\ 2.png');
}

.pageWrapper {
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profileTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.decorativeImageLeft,
.decorativeImageRight {
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  height: 80px;
  width: 250px;
}

.decorativeImageLeft {
  background-image: var(--decorativeImageUrlLeft);
  left: 0;
}

.decorativeImageRight {
  background-image: var(--decorativeImageUrlRight);
  right: 0;
}

.profileTitle {
  font-family: 'Karantina', cursive;
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  color: rgba(97, 9, 9, 1);
  margin: 0 10px;
}

.profilePageWrapper {
  position: relative;
  background: rgba(123, 93, 57, 0.53);
  border-radius: 16px;
  width: 80%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  position: relative;
}

.profilePageWrapper::before,
.profilePageWrapper::after,
.profilePageWrapper .corner::before,
.profilePageWrapper .corner::after {
  content: '';
  position: absolute;
  z-index: 2;
  background-image: url('../assets/frames/corner.png');
  background-size: cover;
}

.profilePageWrapper::before,
.profilePageWrapper::after {
  width: 100px;
  height: 100px;
}

.profilePageWrapper::before {
  top: -13px;
  left: -22px;
  transform: rotate(0deg);
}

.profilePageWrapper::after {
  top: -22px;
  right: -13px;
  transform: rotate(90deg);
}

.profilePageWrapper .corner::before,
.profilePageWrapper .corner::after {
  width: 100px;
  height: 100px;
}

.profilePageWrapper .corner::before {
  bottom: -21px;
  left: -14px;
  transform: rotate(270deg);
}

.profilePageWrapper .corner::after {
  bottom: -12.5px;
  right: -21px;
  transform: rotate(180deg);
}

.profilePage {
  position: relative;
  border-radius: 16px;
  padding: 70px;
  width: 100%;
  box-sizing: border-box;
}

.profilePage::before,
.profilePage::after,
.borderTop,
.borderBottom,
.borderLeft,
.borderRight {
  position: absolute;
  background: linear-gradient(90deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  z-index: 1;
}

.borderTop,
.borderBottom {
  width: 100%;
  height: 7px;
}

.borderTop {
  top: 0;
  left: 0;
  right: 0;
}

.borderBottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.borderLeft,
.borderRight {
  width: 7px;
  height: 100%;
}

.borderLeft {
  top: 0;
  bottom: 0;
  left: 0;
}

.borderRight {
  top: 0;
  bottom: 0;
  right: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
}

.textInput {
  display: flex;
  justify-content: center;
}

.labelValueWrapper {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 2fr;
  align-items: left;
  font-size: clamp(0.7rem, 1vw, 1rem);
}

.label {
  font-weight: bold;
  color: var(--labelColor);
  font-size: 12px;
}

.value {
  font-weight: bold;
  color: var(--valueColor);
}

.centeredItem {
  grid-column: span 3;
  justify-self: center;
}

@media (max-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .centeredItem {
    grid-column: span 1;
  }
}

@media (max-width: 480px) {
  .profileTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: -2em;
    font-size: 12px;
  }

  .profilePageWrapper {
    margin-top: -18px;
    width: calc(100% - 50px);
    height: calc(100vh - 180px);
    padding-bottom: 1.8em;
  }

  .wrapper {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .profilePage {
    overflow: auto;
    max-height: auto;
    padding: 0px;
    padding-bottom: 5px;
  }

  .profileTitle {
    font-size:3.5em;
  }

  .pageWrapper {
    height: 110%;
  }

  .label {
    font-weight: bold;
    color: var(--labelColor);
  }

  .value {
    font-weight: bold;
    color: var(--valueColor);
  }

  .decorativeImageLeft,
  .decorativeImageRight {
    background-repeat: no-repeat;
    position: relative;
    height: 60px;
    width: 250px;
    margin-top: 20px;
  }

  .decorativeImageLeft {
    background-image: var(--decorativeImageUrlLeft);
    left: 0;
  }

  .decorativeImageRight {
    background-image: var(--decorativeImageUrlRight);
    right: 0;
  }

  .labelValueWrapper {
    padding: 0.7em;
  }
}

/* Landscape mode styles */
@media only screen and (orientation: landscape) and (max-width: 999px) {
  .profileTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: -40px;
  }

  .profileTitle {
    font-size: 40px;
  }
  .profilePageWrapper {
    margin-top: -20px;
    width: calc(100% - 50px);
    padding-bottom: 10px;
    height: calc(100vh - 160px);
  }

  .wrapper {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .profilePage {
    overflow: auto;
    max-height: auto;
    padding: 0px;
    padding-bottom: 5px;
  }

  .pageWrapper {
    height: 100vh;
  }

  .label {
    font-weight: bold;
    color: var(--labelColor);
  }

  .value {
    font-weight: bold;
    color: var(--valueColor);
  }

  .decorativeImageLeft,
  .decorativeImageRight {
    background-repeat: no-repeat;
    position: relative;
    height: 50px;
    width: 170px;
  }

  .decorativeImageLeft {
    background-image: var(--decorativeImageUrlLeft);
    left: 0;
  }

  .decorativeImageRight {
    background-image: var(--decorativeImageUrlRight);
    right: 0;
  }

  .profilePageWrapper::before,
  .profilePageWrapper::after {
    width: 80px;
    height: 80px;
  }

  .profilePageWrapper::before {
    top: -10px;
    left: -16px;
    transform: rotate(0deg);
  }

  .profilePageWrapper::after {
    top: -16px;
    right: -10px;
    transform: rotate(90deg);
  }

  .profilePageWrapper .corner::before,
  .profilePageWrapper .corner::after {
    width: 80px;
    height: 80px;
  }

  .profilePageWrapper .corner::before {
    bottom: -16px;
    left: -10px;
    transform: rotate(270deg);
  }

  .profilePageWrapper .corner::after {
    bottom: -10px;
    right: -16px;
    transform: rotate(180deg);
  }

}