@import url('https://fonts.googleapis.com/css2?family=Inder&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Katibeh&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inder&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Katibeh&display=swap');

.login {
  background-image: url('../assets/eventshorizontal.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 30px;
  overflow: auto;
}

.bgimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -2;
}

.cover {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 91%;
  height: 90%;
  min-width: 230px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 3%;

  background: rgba(143, 143, 138, 0.309);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 2px solid rgba(255, 255, 255, 0.3);

}

.link {
  color: white;
  text-decoration: none;
  /* font-size: 30px; */
  align-self: flex-start;
  font-weight: 600;
  text-shadow: 0px 2px #60606060
}

.homebtn {
  position: absolute;
  top: 5%;
  left: 7%;
  /* height: 25px; */
  /* width: 60px; */
  padding: 10px;
  background-color: #429aa761;
  border: 2px solid #14D7F550;
  border-radius: 15px;

}

.homebtn:hover {
  background-color: #4494a088;
  border: 2px solid #14D7F5;
}

.loginbox {
  position: absolute;
  top: 31%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 2px #5a5151ff;
}

.logintitle {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2;
  color: white;
  margin-bottom: 20px;
  font-family: 'Inknut Antiqua';
}

.logintitle2 {
  margin-top: 0px;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: white;
  padding: 0px 20px;
  font-family: 'Inter';
}

.loginbut {
  margin: 8px 0px 10px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 35px;
  background-image: linear-gradient(to top, #d8d9db 0%, #fff 80%, #fdfdfd 100%);
  border-radius: 30px;
  border: 1px solid #8f9092;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 600;
  color: #606060;
  text-shadow: 0 1px #fff;
}

.loginbut:hover {
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 3px 3px #cecfd1;
}

.loginbut:active {
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}

.loginbut:focus {
  box-shadow: 0 4px 3px 1px #fcfcfc, 0 6px 8px #d6d7d9, 0 -4px 4px #cecfd1,
    0 -6px 4px #fefefe, inset 0 0 5px 3px #999, inset 0 0 30px #aaa;
}


.glogin {
  height: 40px;
  width: 205px;
  color: #610909;
  margin-bottom: 20px;
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
  transition: all 1000ms;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  font-family: 'Inria Sans';

}

.glogin:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid red;
  box-shadow: 4px 5px 17px -4px maroon;
}

.glogin::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: maroon;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.glogin:hover::before {
  width: 250%;
}

.signuplink {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: slategray;
}

.signuptext {
  margin: 5px 0px 10px 52px;
}