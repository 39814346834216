@import url("https://fonts.googleapis.com/css2?family=Karantina:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inder&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap');

.heading {
  font-family: "Karantina", sans-serif;
  font-size: 40px;
  font-weight: 900;
  color: #610909;
  margin-top: 50px;
  position: relative;
}

.comp {
  width: 80%;
  height: calc(100vh - 220px);
  margin-top: -20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  /* box-sizing: border-box; */
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  color: var(--headingColor);
}

.events {
  font-weight: 700;
  border-bottom: 1px solid var(--headingColor);
}

.afont {
  font-family: "Inknut Antiqua", serif;
}

.ifont {
  font-family: "Inder", serif;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wrapper {
  width: 100%;
  overflow: auto;
}

.row {
  display: flex;
  justify-content: center;
}

.wings {
  position: relative;
  height: 70px;
  margin-top: 30px;
}

.table {
  width: 90%;
  margin-top: -15px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.entry {
  box-sizing: border-box;
  padding: 10px;
  color: #610909;
  margin-bottom: 0%;
}

td,
th {
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
}

td a {
  text-decoration: none;
  color: white;
}

.lbtn {
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  font-style: italic;
  font-weight: 600;
  text-decoration: underline;
}

.rbtn {
  border: none;
  border-radius: 15px;
  background-color: #610909;
  color: white;
  height: 30px;
}

.wrapper::before,
.wrapper::after,
.wrapper .corner::before,
.wrapper .corner::after {
  content: '';
  position: absolute;
  z-index: 2;
  background-image: url('../assets/frames/corner.png');
  background-size: cover;
}

.wrapper::before,
.wrapper::after {
  width: 110px;
  height: 110px;
}

.wrapper::before {
  top: -15px;
  left: -23px;
  transform: rotate(0deg);
}

.wrapper::after {
  top: -24px;
  right: -15px;
  transform: rotate(90deg);
}

.wrapper .corner::before,
.wrapper .corner::after {
  width: 110px;
  height: 110px;
}

.wrapper .corner::before {
  bottom: -24px;
  left: -15px;
  transform: rotate(270deg);
}

.wrapper .corner::after {
  bottom: -15px;
  right: -23.5px;
  transform: rotate(180deg);
}

.borderTop,
.borderBottom,
.borderLeft,
.borderRight {
  position: absolute;
  background: linear-gradient(90deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  z-index: 1;
}

.borderTop,
.borderBottom {
  width: 100%;
  height: 7px;
}

.borderTop {
  top: 0;
  left: 0;
  right: 0;
}

.borderBottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.borderLeft,
.borderRight {
  width: 7px;
  height: 100%;
}

.borderLeft {
  top: 0;
  bottom: 0;
  left: 0;
}

.borderRight {
  top: 0;
  bottom: 0;
  right: 0;
}

@media (max-width: 600px) {
  .heading {
    margin-top: 0.6em;
    font-size: 2.2em;
  }

  .table {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    max-height: auto;
    margin-left: 0px;
  }

  .wrapper {
    padding: 10px;
  }

  .comp {
    margin-top: -1.5em;
    width: 90%;
    height: max-content;
    max-height: 78vh;
    font-size: 0.6rem;
  }

  .wings {
    margin-top: 1em;
    height: 2.4em;
    width: auto;
  }

  .entry {
    box-sizing: border-box;
    padding: 5px;
    color: #610909;
    margin-bottom: 0%;
    width: fit-content;
  }

  .events {
    width: fit-content;

  }

  .wrapper::before,
  .wrapper::after {
    width: 80px;
    height: 80px;
  }

  .wrapper::before {
    top: -9.5px;
    left: -16px;
    transform: rotate(0deg);
  }

  .wrapper::after {
    top: -16.5px;
    right: -10px;
    transform: rotate(90deg);
  }

  .wrapper .corner::before,
  .wrapper .corner::after {
    width: 80px;
    height: 80px;
  }

  .wrapper .corner::before {
    bottom: -15.5px;
    left: -10px;
    transform: rotate(270deg);
  }

  .wrapper .corner::after {
    bottom: -10px;
    right: -16px;
    transform: rotate(180deg);
  }

}

/* Landscape mode styles */
@media only screen and (orientation: landscape) and (max-width: 999px) {
  .heading {
    margin-top: 0.7em;
    font-size: 2.2em;
  }

  .table {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    max-height: auto;
    margin-left: 0px;
  }

  .wrapper {
    padding: 10px;
  }

  .comp {
    margin-top: -20px;
    width: 90%;
    height: 55vh;
    font-size: 0.8rem;
  }

  .wings {
    top: -0.8em;
    height: 3em;
    width: auto;
  }

  .entry {
    box-sizing: border-box;
    padding: 5px;
    color: #610909;
    margin-bottom: 0%;
    width: fit-content;
  }

  .events {
    width: fit-content;

  }

  .wrapper::before,
  .wrapper::after {
    width: 80px;
    height: 80px;
  }

  .wrapper::before {
    top: -9.5px;
    left: -16px;
    transform: rotate(0deg);
  }

  .wrapper::after {
    top: -16.5px;
    right: -10px;
    transform: rotate(90deg);
  }

  .wrapper .corner::before,
  .wrapper .corner::after {
    width: 80px;
    height: 80px;
  }

  .wrapper .corner::before {
    bottom: -15.5px;
    left: -10px;
    transform: rotate(270deg);
  }

  .wrapper .corner::after {
    bottom: -10px;
    right: -16px;
    transform: rotate(180deg);
  }
}