@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
body {
  margin: 0px;
  font-family: "Montserrat", sans-serif;
}
:root {
  /*All the css variables should be declared here*/
  --primaryColor: #19191b;
  --secondaryColor: maroon;
  --shadowColor: rgb(234, 234, 234);
  --descColor: rgb(96, 96, 96);
  --bgColor: black;
  --headingColor: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(228, 225, 225);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(228, 225, 225);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(228, 225, 225);
}

/* Remove Arrow from Number input fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f4f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: maroon;
}
