@import url("https://fonts.googleapis.com/css2?family=Karantina:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap");

.heading {
  font-family: "Karantina", sans-serif;
  font-size: 40px;
  font-weight: 900;
  color: #610909;
  margin-top: 60px;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  justify-content: center;
}

.wings {
  position: relative;
  height: 70px;
  top: 40px;
}

.regs {
  width: 80%;
  height: calc(100vh - 200px);
  margin-top: -15px;
  /* From https://css.glass */
  /* background-color: transparent; */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: auto;
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  color: var(--headingColor);
  position: relative;
}

.wrapper {
  width: 100%;
  overflow: auto;
}

.table {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.entry {
  color: #610909;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 2px solid #977443;
}

td,
th {
  height: 80px;
  box-sizing: border-box;
  padding: 10px;
}

td {
  height: 50px;
}

.submitModal {
  width: 100%;
  height: 20vh;
}

.textInput {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.input {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--headingColor);
  width: 100%;
  height: 30px;
  background-color: transparent;
  caret-color: var(--headingColor);
  color: var(--headingColor);
  z-index: 10;
}

.label {
  color: var(--headingColor);
  height: 30px;
  z-index: -10;
}

.btn {
  background-color: #610909;
  border-radius: 30px;
  color: white;
  border: 0px;
  font-weight: 600;
  height: 26px;
  width: 160px;
  cursor: pointer;
}

.competition {
  color: #2A2A2A;
  font-family: "Inknut Antiqua";
  font-weight: 600;
}

.regs::before,
.regs::after,
.regs .corner::before,
.regs .corner::after {
  content: '';
  position: absolute;
  z-index: 2;
  background-image: url('../assets/frames/corner.png');
  background-size: cover;
}

.regs::before,
.regs::after {
  width: 110px;
  height: 110px;
}

.regs::before {
  top: -15px;
  left: -23px;
  transform: rotate(0deg);
}

.regs::after {
  top: -24px;
  right: -15px;
  transform: rotate(90deg);
}

.regs .corner::before,
.regs .corner::after {
  width: 110px;
  height: 110px;
}

.regs .corner::before {
  bottom: -24px;
  left: -15px;
  transform: rotate(270deg);
}

.regs .corner::after {
  bottom: -15px;
  right: -23.5px;
  transform: rotate(180deg);
}

.borderTop,
.borderBottom,
.borderLeft,
.borderRight {
  position: absolute;
  background: linear-gradient(90deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  z-index: 1;
}

.borderTop,
.borderBottom {
  width: 100%;
  height: 7px;
}

.borderTop {
  top: 0;
  left: 0;
  right: 0;
}

.borderBottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.borderLeft,
.borderRight {
  width: 7px;
  height: 100%;
}

.borderLeft {
  top: 0;
  bottom: 0;
  left: 0;
}

.borderRight {
  top: 0;
  bottom: 0;
  right: 0;
}

@media (max-width: 600px) {
  .heading {
    margin-top: 0.4em;
    font-size: 2em;
  }

  .table {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    max-height: auto;
    margin-left: 0px;
  }

  .wrapper {
    padding: 10px;
  }

  .regs {
    margin-top: -0.5em;
    width: 90%;
    height: 82vh;
    max-height: 90vh;
    font-size: 0.7rem;
  }

  .wings {
    margin-top: -1.8em;
    height: 2.4em;
    width: auto;
  }

  .entry {
    box-sizing: border-box;
    padding: 5px;
    color: #610909;
    margin-bottom: 0%;
    width: fit-content;
  }

  .events {
    width: fit-content;

  }

  .regs::before,
  .regs::after {
    width: 80px;
    height: 80px;
  }

  .regs::before {
    top: -9.5px;
    left: -16px;
    transform: rotate(0deg);
  }

  .regs::after {
    top: -16.5px;
    right: -10px;
    transform: rotate(90deg);
  }

  .regs .corner::before,
  .regs .corner::after {
    width: 80px;
    height: 80px;
  }

  .regs .corner::before {
    bottom: -15.5px;
    left: -10px;
    transform: rotate(270deg);
  }

  .regs .corner::after {
    bottom: -10px;
    right: -16px;
    transform: rotate(180deg);
  }

  .btn {
    height: auto;
    width: auto;
    cursor: pointer;
    font-size: 0.9em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
}

/* Landscape mode styles */
@media only screen and (orientation: landscape) and (max-width: 999px) {
  .heading {
    margin-top: 1.2em;
    font-size: 2.2em;
  }

  .table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    max-height: auto;
    margin-left: 0px;
  }

  .wrapper {
    padding: 10px;
  }

  .regs {
    margin-top: -1.5em;
    width: 90%;
    height: 80vh;
    max-height: 60vh;
    font-size: 0.9rem;
  }

  .wings {
    margin-top: -0.7em;
    height: 3em;
    width: auto;
  }

  .entry {
    box-sizing: border-box;
    padding: 5px;
    color: #610909;
    margin-bottom: 0%;
    width: fit-content;
  }

  .events {
    width: fit-content;

  }

  .regs::before,
  .regs::after {
    width: 80px;
    height: 80px;
  }

  .regs::before {
    top: -9.5px;
    left: -16px;
    transform: rotate(0deg);
  }

  .regs::after {
    top: -16.5px;
    right: -10px;
    transform: rotate(90deg);
  }

  .regs .corner::before,
  .regs .corner::after {
    width: 80px;
    height: 80px;
  }

  .regs .corner::before {
    bottom: -15.5px;
    left: -10px;
    transform: rotate(270deg);
  }

  .regs .corner::after {
    bottom: -10px;
    right: -16px;
    transform: rotate(180deg);
  }
}