.team {
  width: 80%;
  height: calc(100vh - 200px);

  margin-top: -20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.12);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  box-sizing: border-box;
  padding: auto;
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  color: var(--headingColor);
}

.teamContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.wings {
  position: relative;
  height: 60px;
  top: 0px;
  margin-top: -60px;
}

.wingsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}

.teamtitle {
  font-weight: 900;
  font-family: Karantina, sans-serif;
  color: #610909;
  font-size: 40px;
  text-align: center;
  margin-top: -15px;
}

.wrapper {
  width: 100%;
  overflow: auto;
}

.table {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.entry {
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid var(--headingColor);
}

td,
th {
  box-sizing: border-box;
  padding: 10px;
}

.wrapper::before,
.wrapper::after,
.wrapper .corner::before,
.wrapper .corner::after {
  content: '';
  position: absolute;
  z-index: 2;
  background-image: url('../assets/frames/corner.png');
  background-size: cover;
}

.wrapper::before,
.wrapper::after {
  width: 110px;
  height: 110px;
}

.wrapper::before {
  top: -15px;
  left: -23px;
  transform: rotate(0deg);
}

.wrapper::after {
  top: -24px;
  right: -15px;
  transform: rotate(90deg);
}

.wrapper .corner::before,
.wrapper .corner::after {
  width: 110px;
  height: 110px;
}

.wrapper .corner::before {
  bottom: -23px;
  left: -16px;
  transform: rotate(270deg);
}

.wrapper .corner::after {
  bottom: -15px;
  right: -23.5px;
  transform: rotate(180deg);
}

.borderTop,
.borderBottom,
.borderLeft,
.borderRight {
  position: absolute;
  background: linear-gradient(90deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  z-index: 1;
}

.borderTop,
.borderBottom {
  width: 100%;
  height: 7px;
}

.borderTop {
  top: 0;
  left: 0;
  right: 0;
}

.borderBottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.borderLeft,
.borderRight {
  width: 7px;
  height: 100%;
}

.borderLeft {
  top: 0;
  bottom: 0;
  left: 0;
}

.borderRight {
  top: 0;
  bottom: 0;
  right: 0;
}

@media (max-width: 600px) {
  .teamContainer {
    width: 80%;
    height: calc(100vh - 75px);
  }

  .table {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    max-height: auto;
  }

  .teamtitle {
    width: 90%;
    font-size: 2.5rem;
    margin-top: 0.8em;
  }

  .wrapper {
    padding: 10px;
  }

  .team {
    margin-top: -1.5em;
    width: 100%;
    height: 100%;
  }

  .wrapper::before,
  .wrapper::after {
    width: 90px;
    height: 90px;
  }

  .wrapper::before {
    top: -12px;
    left: -18px;
    transform: rotate(0deg);
  }

  .wrapper::after {
    top: -19px;
    right: -11.5px;
    transform: rotate(90deg);
  }

  .wrapper .corner::before,
  .wrapper .corner::after {
    width: 90px;
    height: 90px;
  }

  .wrapper .corner::before {
    bottom: -20px;
    left: -12px;
    transform: rotate(270deg);
  }

  .wrapper .corner::after {
    bottom: -11.5px;
    right: -18.5px;
    transform: rotate(180deg);
  }

  .wings {
    margin-top: 0em;
    height: 2.8em;
    width: auto;
  }

}

/* Landscape mode styles */
@media only screen and (orientation: landscape) and (max-width: 999px) {
  .table {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    max-height: auto;
  }

  .wrapper {
    padding: 10px;
  }

  .team {
    width: 80%;
    height: calc(100vh - 120px);
  }

  .wrapper::before,
  .wrapper::after {
    width: 80px;
    height: 80px;
  }

  .wrapper::before {
    top: -9.5px;
    left: -16px;
    transform: rotate(0deg);
  }

  .wrapper::after {
    top: -16.5px;
    right: -10px;
    transform: rotate(90deg);
  }

  .wrapper .corner::before,
  .wrapper .corner::after {
    width: 80px;
    height: 80px;
  }

  .wrapper .corner::before {
    bottom: -15.5px;
    left: -10px;
    transform: rotate(270deg);
  }

  .wrapper .corner::after {
    bottom: -10px;
    right: -16px;
    transform: rotate(180deg);
  }

}