.register {
  background: radial-gradient(circle at 24.1% 68.8%,
      rgb(50, 50, 50) 0%,
      rgb(0, 0, 0) 99.4%);
  height: 100vh;
  /* height: 100vh; */
  /* overflow: auto; */
  box-sizing: border-box;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 40px
}

.button1 {
  width: 120px;
  color: white;
  background-color: #610909;
  border: 0px;
  height: 50px;
  border-radius: 18px;
  cursor: pointer;
  font-size: 1.2em;
  margin: 10px;
}

.button1:hover {
  background-color: white;
  color: #610909;
  transform: scale(1.1);
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
  transition-delay: 0.2s;
}

.registertitle {
  font-weight: 900;
  font-family: Karantina, sans-serif;
  color: #610909;
  font-size: 40px;
  text-align: center;
  margin-top: 55px;
}

@media (max-width: 600px) {
  .registertitle {
    margin-top: 25px;
    font-size: 35px;
  }
}

.wings {
  position: relative;
  height: 90px;
  top: 0px;
}

@media (max-width: 600px) {
  .wings {
    margin-top: -8px;
    height: 42px;
    width: auto;
  }
}

.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-image: url('../assets/backcompjpg.jpg');
  background-size: cover;
  background-position: center;
}

.wingsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}

.registertext {
  text-align: center;
  color: black;
  margin-bottom: 10px;
  font-family: "Inknut Antiqua", serif;
  font-weight: 400;
  font-style: normal;
}

.details {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px;
  justify-content: space-evenly;
  align-items: flex-start;
  overflow: auto;
  max-height: 70vh;
}

@media (max-width: 600px) {
  .details {
    max-height: 60vh;
  }
}

.field {
  color: black;
  font-family: "Inder", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 10px;
  width: 100%;
  max-width: 300px;
}

.inputbox {
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  height: 30px;
  outline: none;
  font-family: "Inknut Antiqua", serif;
  font-weight: 400;
  font-style: normal;
  background-color: transparent;
  color: white;
  font-size: 14px;
}

@media (min-width: 600px) {
  .field {
    width: 48%;
    margin: 5px;
  }
}

.toolTipContent {
  font-size: 10px;
  color: white;
}

.cover {
  width: 80%;
  height: calc(100vh - 200px);
  margin-top: -20px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  color: var(--headingColor);
  background: #7b5d3987;
  position: relative;
}

@media (max-width: 600px) {
  .cover {
    width: calc(100% - 40px);
    padding-bottom: 20px;
    height: calc(100vh - 107px);
    max-height: calc(100vh - 107px);
  }

  .field {
    width: calc(100% - 20px);
    margin: 5px 10px;
  }

  .registertext {
    margin-top: 20px;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

/* Corner images */
.cover::before,
.cover::after,
.cover .corner::before,
.cover .corner::after {
  content: '';
  position: absolute;
  z-index: 2;
  background-image: url('../assets/frames/corner.png');
  background-size: cover;
}

.cover::before,
.cover::after {
  width: 120px;
  height: 120px;
}

.cover::before {
  top: -16.5px;
  left: -25px;
  transform: rotate(0deg);
}

.cover::after {
  top: -25px;
  right: -17px;
  transform: rotate(90deg);
}

.cover .corner::before,
.cover .corner::after {
  width: 120px;
  height: 120px;
}

.cover .corner::before {
  bottom: -23px;
  left: -16px;
  transform: rotate(270deg);
}

.cover .corner::after {
  bottom: -16px;
  right: -25px;
  transform: rotate(180deg);
}

@media (max-width: 600px) {

  .cover::before,
  .cover::after {
    width: 80px;
    height: 80px;
  }

  .cover::before {
    top: -10px;
    left: -15px;
  }

  .cover::after {
    top: -15px;
    right: -10px;
  }

  .cover .corner::before,
  .cover .corner::after {
    width: 80px;
    height: 80px;
  }

  .cover .corner::before {
    top: auto;
    bottom: -15px;
    left: -9px;
  }

  .cover .corner::after {
    top: auto;
    bottom: -10px;
    right: -15px;
  }
}

.topBorder,
.bottomBorder,
.leftBorder,
.rightBorder {
  position: absolute;
  background: linear-gradient(90deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  z-index: 1;
}

.topBorder,
.bottomBorder {
  width: 100%;
  height: 7px;
}

.leftBorder,
.rightBorder {
  width: 7px;
  height: 100%;
}

.topBorder {
  top: 0;
  left: 0;
  right: 0;
}

.bottomBorder {
  bottom: 0;
  left: 0;
  right: 0;
}

.leftBorder {
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
}

.rightBorder {
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
}

.wrapper {
  width: 100%;
  overflow: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .buttonContainer {
    flex-direction: column;
    margin-top: 20px;
  }
}

@media (max-width: 1024px) and (max-height: 600px) {
  .cover {
    width: calc(100% - 60px);
    height: calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    padding: 10px;
  }

  .wrapper {
    padding: 10px;
  }

  .registertext {
    margin-top: 0px;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .field {
    width: 100%;
    max-width: none;
    margin: 5px 0;
  }

  .inputbox {
    font-size: 12px;
  }

  .submitbut {
    width: 80px;
    height: 30px;
    font-size: 12px;
  }

  .registertitle {
    margin-top: 12px;
    font-size: 40px;
  }

  .wings {
    margin-top: -25px;
    height: 70px;
    width: auto;
  }

  .buttonContainer {
    flex-direction: column;
    margin-top: 10px;
  }

  .details {
    max-height: 60vh;
  }

  .option {
    font-size: 14px;
    padding: 6px 10px;
    margin: 2px 0;
    max-height: 20px;
    overflow-y: auto;
  }

}

.field select {
  background-color: transparent;
  border-radius: 5px;
  font-family: "Inknut Antiqua", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.field select:hover {
  background-color: #610909;
  color: white;
  border-color: #610909;
}

.field select option {
  background-color: white;
  color: #610909;
  font-family: "Inknut Antiqua", serif;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}

.field select option:hover {
  background-color: #610909;
  color: white;
}

@media screen and (min-width: 450px) and (max-width: 600px) {
  .buttons {
    position: relative;
    justify-content: center;
    display: flex;
    width: 100%;
    right: 0px;
    padding-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .field select option {
    font-size: 0.8em;
    max-height: 2px;
    overflow-y: auto;
  }
}

/* Landscape mode styles */
@media only screen and (orientation: landscape) and (max-width: 999px) {
  .cover {
    width: 90%;
    padding-bottom: 20px;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 50px);
  }

  .field {
    width: calc(100% - 20px);
  }

  .registertext {
    margin-top: 5px;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .registertitle {
    margin-top: 10px;
    font-size: 35px;
  }

  .wings {
    margin-top: -20px;
    height: 50px;
    width: auto;
  }

  .buttonContainer {
    margin-top: 5px;
    flex-direction: column;
    padding-top: 0px;
  }

  .details {
    max-height: 50vh;
    padding-left: 10px;
    padding-bottom: 10px;
    position: relative;
    width: auto;
    padding-top: -5px;
  }

  .details::-webkit-scrollbar {
    margin-right: -20px;
  }

  .details::-webkit-scrollbar-track {
    background-color: white;
  }

  .option {
    font-size: 14px;
    padding: 6px 10px;
    margin: 2px 0;
    max-height: 20px;
    overflow-y: auto;
  }

  .field select option {
    font-size: 14px;
  }

  .field select option:hover {
    font-size: 14px;
  }

  .registerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-image: url('../assets/backcompjpg.jpg');
    background-size: cover;
    background-position: center;
  }

  .wingsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
  }

  .wrapper {
    padding: 8px;
  }

  .cover::before,
  .cover::after {
    width: 70px;
    height: 70px;
  }

  .cover::before {
    top: -8px;
    left: -13px;
  }

  .cover::after {
    top: -13px;
    right: -7px;
  }

  .cover .corner::before,
  .cover .corner::after {
    width: 70px;
    height: 70px;
  }

  .cover .corner::before {
    top: auto;
    bottom: -12.5px;
    left: -8px;
  }

  .cover .corner::after {
    top: auto;
    bottom: -8.5px;
    right: -13.5px;
  }

  .topBorder,
  .bottomBorder,
  .leftBorder,
  .rightBorder {
    position: absolute;
    background: linear-gradient(90deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
    z-index: 1;
  }

  .topBorder,
  .bottomBorder {
    width: 100%;
    height: 7px;
  }

  .leftBorder,
  .rightBorder {
    width: 7px;
    height: 100%;
  }

  .topBorder {
    top: 0;
    left: 0;
    right: 0;
  }

  .bottomBorder {
    bottom: 0;
    left: 0;
    right: 0;
  }

  .leftBorder {
    top: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  }

  .rightBorder {
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(98, 70, 44, 0.55) 0%, #5B402A 100%);
  }

  .wrapper {
    width: 100%;
    overflow: auto;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 10px;
  }

}
