.mainbar {
  height: 50px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: end;
  background: transparent;
  position: absolute;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
}

.scrollBg {
  background: radial-gradient(
    circle at 24.1% 68.8%,
    rgb(50, 50, 50) 0%,
    rgb(0, 0, 0) 99.4%
  );
}

.logo {
  width: 30px;
  margin: 2px 20px 2px 20px;
}

.logotitle {
  width: 200px;
}

.links {
  margin-left: 65%;
  font-size: 20px;
  font-weight: 600;
}
.link {
  color: white;
  margin: 0px 10px 0px 10px;
  text-decoration: none;
  font-size: 18px;
}

.link:hover {
  color: maroon;
}

/* @media (max-width: 1000px) {
  .links {
    display: none;
  }
} */
