.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(231, 111, 81, 0.37);
  color: rgba(0, 0, 0, 1);
  flex-wrap: nowrap;
  padding: 10px 20px;
}

.left {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.right {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.socialIcons {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin: auto;
  text-decoration: underline;
  padding-left: 7%;
}

.icon {
  transition: transform 0.3s ease;
  font-size: 12px;
  color: #610909;
}

.icon:hover {
  transform: scale(1.1);
}

.getApp {
  cursor: pointer;
  transition: color 0.3s, text-decoration 0.3s;
  font-size: 15px;
  /* Responsive font size */
  padding-left: .65vw;
}

.getApp:hover {
  color: rgb(92, 29, 29);
}

.icon {
  width: 22px;
  height: 22px;
}

@media (max-width: 600px) {

  .footer {
    padding: 2vw;
  }


  .left,
  .right {
    font-size: 3vw;

  }

  .icon {
    width: 4vw;
    height: 4vw;
  }

  .getApp {
    font-size: 3vw;
    display: none;
  }

  .iitk {
    display: none;
  }
}