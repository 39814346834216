.btn {
  outline: none;
  border: 2px solid #610909;
  min-height: 30px;
  box-sizing: border-box;
  padding: 5px;
  min-width: 100px;
  border-radius: 20px;
  transition: 0.1s;
  margin: 5px;
  background-color: #610909;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.btn:hover {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
}

.btn:active {
  transform: translateY(2px);
}

.btnGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 10px;
}
